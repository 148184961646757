<template>
  <modal @close="$emit('close')">
    <div class="max-w-xl w-full">
      <div class="text-xl text-center">
        <p>นั่นแน่...</p>
        <p>แน่ใจนะว่าจะออกจากห้อง</p>
        <p>หรือแค่เผลอกดไป</p>
      </div>
      <img
        src="/counter-page/wanna-switch-avatar.png"
        class="w-24 mx-auto my-8"
      >
      <div class="flex justify-center">
        <dom-button
          class="py-3 px-5 w-40"
          @click.native="$emit('confirm')"
        >
          <p class="text-lg">
            แน่ใจ
          </p>
        </dom-button>
        <dom-button
          class="py-3 px-8 w-40"
          @click.native="$emit('close')"
        >
          <p class="text-lg">
            เผลอไป
          </p>
        </dom-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'
import DomButton from '~/components/DomButton.vue'

export default {
  components: { DomButton, Modal },
}
</script>
