<template>
  <modal
    class="text-center text-paragraph-gray"
    @close="$emit('close')"
  >
    <div class="max-w-sm text-center pb-8 px-4">
      <img
        src="/close-modal.png"
        class="absolute w-9 right-10 cursor-pointer"
        @click="$emit('close')"
      >
      <img
        src="/bar-full.png"
        class="bar-full-img"
      >
      <div class="flex flex-col items-center pt-5">
        <p>Due to a high demand, Please come back again in 10 Minute ...</p>
        <p>Sorry for incoveniences.</p>
        <p class="mt-3">
          We have another branch in Discord that you may like
        </p>
        <a
          class="cursor-pointer px-1 h-full w-12"
          target="_blank"
          href="https://discord.gg/EJeRUY4WBn"
        ><img src="~/public/assets/images/social/discord.png"></a>
        <p class="mt-3">
          Or you can help us expand our bar spaces here
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    Modal,
  },
}
</script>

<style lang="scss" scoped>
.bar-full {
  height: 700px;
  padding: 50px 100px;
  @media only screen and (max-width: 550px) {
    padding: 50px 30px
  }
  max-width: 700px;

  .bar-full-img {
    margin-top: 30px;
    max-width: 500px;
  }
}
</style>
