<template>
  <div class="h-full flex flex-col flex-1 items-center justify-start text-center m-5">
    <img
      class="w-44"
      src="/logo.png"
    >
    <img
      src="https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/public-web-app/images/lobby/chatbox-greet.png"
      class="my-2 mx-auto w-64"
    >
    <div class="text-2xl">
      ยินดีต้อนรับสู่เว็บบาร์ทิพย์
    </div>
    <div class="mb-14 text-paragraph-gray">
      พื้นที่สบายใจสำหรับพูดคุยเรื่องราวและแบ่งปัน ความรู้สึกสุข ทุกข์ เหงา สนุก
      ฯลฯ ไปด้วยกัน
    </div>
    <div>
      เพื่อประสบการณ์เล่นที่ดี รบกวนก๊อบลิงค์เว็บไปเปิดผ่าน Browser Google
      Chrome โดยตรงค่ะ
    </div>
    <button
      class="w-40"
      @click="onClickCopy"
    >
      <img src="/copy-button.png">
    </button>
    <div
      class="underline mt-2 cursor-pointer text-secondary"
      @click="$emit('standalone-browser-clicked')"
    >
      กดที่นี่หากเข้าผ่าน Google Chrome อยู่แล้ว
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickCopy() {
      try {
        navigator.clipboard.writeText('https://drinksonme.live')
        this.$notify({
          type: 'success',
          title: 'Copied',
          text: 'Link copied to clipboard',
        })
      }
      catch (e) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Failed to copy link to clipboard',
        })
      }
    },
  },
}
</script>
