<template>
  <modal @close="$emit('close')">
    <div
      data-testid="log-in-modal"
      class="max-w-xl text-center"
    >
      <div class="max-w-sm mx-auto">
        <p class="my-4 text-3xl">
          ล็อกอินแล้วดีขึ้นเยอะ !
        </p>
        <div class="mt-3 lg:mt-0 max-w-md text-left mx-auto">
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              ได้ใส่หมวกเก๋ ๆ เข้าบาร์
            </p>
          </div>
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              เพิ่มความปลอดภัย รีพอร์ตยูสเซอร์ได้
            </p>
          </div>
          <div class="flex items-center py-1">
            <img
              draggable="false"
              src="/landing/tick.png"
              class="h-7 mr-2"
            >
            <p class="text-lg">
              ไม่หลุดออกจากห้อง เวลาเผลอรีเฟรช
            </p>
          </div>
        </div>
        <div class="relative my-5">
          <img
            draggable="false"
            src="https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/accessories/hats/discord-1.png"
            class="perks-hat"
          >
          <img
            draggable="false"
            src="/landing/discord-perks.png"
            alt="discord-perks"
            class="max-w-md mx-auto w-full"
          >
        </div>
        <img
          data-testid="discord-log-in"
          src="/login/login-with-discord.png"
          class="cursor-pointer"
          @click="loginWithDiscord"
        >
        <img
          data-testid="google-log-in"
          src="/login/login-with-google.png"
          class="cursor-pointer"
          @click="loginWithGoogle"
        >
        <div class="text-sm mt-4">
          <p>*แนะนำให้ใช้ Browser Chrome หรือ Firefox ในการเล่นเว็บไซต์</p>
          <p>หากเข้าลิงค์จาก Social Media อาจเกิดปัญหาในการล็อกอิน</p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'

export default {
  components: { Modal },
  methods: {
    async loginWithDiscord() {
      try {
        this.$store.setPageLoading(true)
        await this.$supabase.auth.signInWithOAuth({
          provider: 'discord',
          options: {
            redirectTo: `${this.$config.public.appBaseURL}${this.$route.path}`,
          },
        })
      }
      catch (error) {
        console.error('discord login error', error)
        this.$store.setPageLoading(false)
      }
    },
    async loginWithGoogle() {
      try {
        this.$store.setPageLoading(true)
        await this.$supabase.auth.signInWithOAuth({
          provider: 'google',
          options: {
            redirectTo: `${this.$config.public.appBaseURL}${this.$route.path}`,
          },
        })
        console.log('loginWithGoogle')
      }
      catch (error) {
        console.error('discord login error', error)
        this.$store.setPageLoading(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.perks-hat {
  position: absolute;
  height: 45px;
  top: -16px;
  left: calc(50% - 16px);

  @media only screen and (max-width: 1024px) {
    height: 42px;
    top: -18px;
  }
}
</style>
