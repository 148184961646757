<template>
  <div @click="onClick" class="flex items-center px-3 py-2" :class="read ? 'bg-white' : 'bg-cream-blue'">
    <div class="flex-1 flex flex-col items-start">
      <p class="text-sm text-start">{{ message }}</p>
      <p class="text-xs text-paragraph-gray text-start mt-1">{{ formattedTime }}</p>
    </div>
    <div v-if="!clicked" class="w-2 h-2 bg-banyen rounded-full" />
    <div v-else class="w-2 h-2" />
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration.js'
import global from '~/mixins/global'
dayjs.extend(duration)

export default {
  mixins: [global],
  props: {
    notificationId: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    read: {
      type: Boolean,
      required: true,
    },
    clicked: {
      type: Boolean,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedTime () {
      const diff = dayjs().diff(dayjs(this.time), 'minute')
      if (diff < 1) {
        return 'เมื่อสักครู่'
      } else if (diff < 60) {
        return `${diff} นาทีที่แล้ว`
      } else if (diff < 1440) {
        return `${diff} ชั่วโมงที่แล้ว`
      } else {
        return dayjs(this.time).format('DD/MM/YYYY')
      }
    },
  },
  methods: {
    async onClick () {
      await $fetch('/api/notification/click', {
        method: 'POST',
        baseURL: this.$config.public.appBaseURL,
        headers: this.headers,
        body: {
          notificationId: this.notificationId,
        },
      })
      this.$emit('clicked')
    },
  },
}
</script>