<template>
  <modal
    data-testid="support-us-modal"
    @close="$emit('close')"
  >
    <div class="max-w-sm mx-auto pb-8 px-4 text-center">
      <img
        src="/creators.gif"
        class="creators mx-auto"
      >
      <p class="font-semibold text-3xl">
        Buy us a Coffee via Kofi
      </p>
      <div class="my-4">
        <a
          href="https://ko-fi.com/I3I3EJURQ"
          target="_blank"
        >
          <img
            height="45"
            style="border:0px;height:45px;"
            src="https://cdn.ko-fi.com/cdn/kofi2.png?v=3"
            border="0"
            alt="Buy Me a Coffee at ko-fi.com"
            class="mx-auto"
          >
        </a>
      </div>
      <p class="font-semibold text-3xl">
        or
      </p>
      <p class="font-semibold text-3xl">
        via bank transfer
      </p>
      <img
        src="/support-us-qr.png"
        class="mx-auto my-4 w-48"
      >
      <p>ธนาคาร: กสิกรไทย</p>
      <p>ชื่อ: น.ส. ชลิพา ดุลยากร</p>
      <div class="flex justify-center items-center">
        <p>บัญชี: 506-2-34535-9</p>
        <img
          src="~/public/assets/images/icons/document.png"
          class="h-4 cursor-pointer ml-2"
          @click="copyBankAccount"
        >
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    Modal,
  },
  methods: {
    copyBankAccount() {
      navigator.clipboard.writeText('5062345359')
    },
  },
}
</script>
