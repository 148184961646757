<template>
  <div
    data-testid="footer"
    class="flex justify-center py-3"
  >
    <div class="flex justify-center items-center">
      <p class="text-paragraph-gray">
        <span
          class="underline cursor-pointer"
          @click="$emit('about-us-clicked')"
        >
          About Us
        </span>
        |
        <a
          class="underline cursor-pointer"
          target="_blank"
          href="https://drinksonme.notion.site/37d5d9acab1b4f4ab167f5bd20c68b70"
        >
          Collab With Us
        </a>
        |
        <span
          class="underline cursor-pointer"
          @click="$emit('support-us-clicked')"
        >
          Support Us
          <!-- <a v-if="enableKofi" href="https://ko-fi.com/I3I3EJURQ" target="_blank">Support Us</a> -->
        </span>
      </p>
      <div class="flex justify-center mx-1">
        <a
          class="cursor-pointer w-9 px-1"
          target="_blank"
          href="https://discord.gg/EJeRUY4WBn"
        ><img src="~/public/assets/images/social/discord.png"></a>
        <a
          class="cursor-pointer w-9 px-1"
          target="_blank"
          href="https://www.instagram.com/drinksonme.live"
        ><img src="~/public/assets/images/social/ig.png"></a>
        <a
          class="cursor-pointer w-9 px-1"
          target="_blank"
          href="https://www.tiktok.com/@namototae?_t=8VBeII2bFMv&_r=1"
        ><img src="~/public/assets/images/social/tiktok.png"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
