<template>
  <section>
    <h1 class="text-4xl text-black font-bold mb-4">
      DrinksOnMe bar is under server maintanence 🏗️
    </h1>
    <h3 class="text-paragraph-gray">
      Sorry for inconveniences, we will be back as soon as possible !
    </h3>
    <img
      draggable="false"
      src="/bartender-greet.png"
      class="max-h-80 mt-12"
    >
  </section>
</template>

<style lang="scss" scoped>
section {
  @apply py-14 flex flex-col items-center;
}
</style>
